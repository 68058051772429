import * as React from 'react';
import { useState } from "react";
import ProfileSettings from '../components/Settings/ProfileSettings';
import SearchSettings from '../components/Settings/SearchSettings';

function Settings() {
    const [toggle, setToggle] = useState(1);

    return (
        <div>
            <div className='tab'>
                <ul className='d-flex'>
                    <li className={toggle === 1 ? 'flex-fill active' : 'flex-fill'} onClick={() => setToggle(1)}>Bildersuche</li>
                    {/* <li className={toggle === 2 ? 'flex-fill active' : 'flex-fill'} onClick={() => setToggle(2)}>Bezahlmethode</li> */}
                    <li className={toggle === 3 ? 'flex-fill active' : 'flex-fill'} onClick={() => setToggle(3)}>Profil</li>
                </ul>
                <>
                    <div className={toggle === 1 ? "show-content" : "tab-content"}>
                        {toggle === 1 ?
                            <SearchSettings /> : <></>}
                    </div>
                    {/* <div className={toggle === 2 ? "show-content" : "tab-content"}>
                        <PaymentSettings />
                    </div> */}
                    <div className={toggle === 3 ? "show-content" : "tab-content"}>
                        <ProfileSettings />
                    </div>
                </>

            </div>
        </div>
    );
}

export default Settings;