import * as React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import axios from "../../api/axios";
const REGISTER_URL = "/auth/signup";

const Register = () => {
  const navigate = useNavigate();
  const [success, setSuccess] = useState(false)
  const [emailError, setEmailError] = useState("")
  const [passwordError, setPasswordError] = useState("")
  const handleSubmit = async (e) => {
    e.preventDefault();
    setEmailError("")
    setPasswordError("")
    try {
      const formData = new FormData(e.target);

      const user = Object.fromEntries(formData);

      if (user.password === user.passwordConfirm) {
        await axios.post(REGISTER_URL, user);
        setSuccess(true)
      } else {
        setPasswordError("Passwords do not match")
      }
    } catch (err) {
      if (err?.response?.status === 422) {
        setEmailError("Email already in use")
      } else {
        alert("No backend connection")
      }
    }
  };

  return (
    <>

      <div className="position-absolute container-fluid align-center">
        <div className="d-flex justify-content-center fw-bold fs-3">
          <p>Willkommen bei United Creatives!</p>
        </div>
        <div className="container mt-5">
          {success ?
            <div>Aktivierungslink wurde an die Email Adresse geschickt</div>
            :
            <form onSubmit={handleSubmit}>
              {/* <div className="d-flex form-group mb-3">
                <input
                  type="text"
                  name="companyName"
                  className="form-control"
                  id="companyName"
                  aria-describedby="companyName"
                  placeholder="Firmenname"
                />
                <i className="p-3 bi bi-person-fill icon"></i>
              </div>
              <div className="d-flex form-group mb-3">
                <input
                  type="text"
                  name="website"
                  className="form-control"
                  id="website"
                  aria-describedby="website"
                  placeholder="Website"
                />
                <i className="p-3 bi bi-person-fill icon"></i>
              </div> */}
              <div className="d-grid form-group mb-3">
                {/* <input
                  type="text"
                  name="salutation"
                  className="form-control"
                  id="Salutation"
                  aria-describedby="anredeHelp"
                  placeholder="Anrede"
                  required
                /> */}
                <label for="salutation-select">Anrede</label>
                <div className="d-flex">
                  <select name="salutation" id="salutation-select" style={{ width: "100%", borderRadius: "5px", border: "none", borderTopRightRadius: "0px", borderBottomRightRadius: "0px", paddingLeft: "9px" }}>
                    <option value="Herr">Herr</option>
                    <option value="Frau">Frau</option>
                  </select>
                  <i className="p-2 bi bi-person-fill icon"></i>
                </div>
              </div>
              <div className="d-grid form-group mb-3">
                <label for="salutation-select">Titel</label>
                <div className="d-flex">
                  <input
                    type="text"
                    name="title"
                    className="form-control"
                    id="Title"
                    aria-describedby="titelHelp"
                    placeholder="Titel"
                    style={{ borderTopRightRadius: "0px", borderBottomRightRadius: "0px" }}
                  />
                  {/* <select name="title" id="title-select" style={{ width: "100%", borderRadius: "5px", border: "none" }}>
                  <option value="Doktor">Doktor</option>
                </select>*/}
                  <i className="p-2 bi bi-person-fill icon"></i>
                </div>
              </div>
              <div className="d-grid form-group mb-3">
                <label for="salutation-select">Vorname</label>
                <div className="d-flex">
                  <input
                    type="text"
                    name="firstName"
                    className="form-control"
                    id="FirstName"
                    aria-describedby="vornameHelp"
                    placeholder="Vorname"
                    style={{ borderTopRightRadius: "0px", borderBottomRightRadius: "0px" }}
                    required
                  />
                  <i className="p-2 bi bi-person-fill icon"></i>
                </div>
              </div>
              <div className="d-grid form-group mb-3">
                <label for="salutation-select">Nachname</label>
                <div className="d-flex">
                  <input
                    type="text"
                    name="lastName"
                    className="form-control"
                    id="LastName"
                    placeholder="Nachname"
                    style={{ borderTopRightRadius: "0px", borderBottomRightRadius: "0px" }}
                    required
                  />
                  <i className="p-2 bi bi-person-fill icon"></i>
                </div>
              </div>
              {/* <div className="d-flex form-group mb-3">
                <input
                  type="text"
                  name="street"
                  className="form-control"
                  id="Street"
                  aria-describedby="vornameHelp"
                  placeholder="Straße"
                  required
                />
                <i className="p-2 bi bi-person-fill icon"></i>
              </div>
              <div className="d-flex form-group mb-3">
                <input
                  type="text"
                  name="houseNumber"
                  className="form-control"
                  id="HouseNumber"
                  aria-describedby="hausnummerHelp"
                  placeholder="Hausnummer"
                  required
                />
                <i className="p-2 bi bi-person-fill icon"></i>
              </div>
              <div className="d-flex form-group mb-3">
                <input
                  type="text"
                  name="postalCode"
                  className="form-control"
                  id="PostalCode"
                  aria-describedby="vornameHelp"
                  placeholder="PLZ"
                  required
                />
                <i className="p-2 bi bi-person-fill icon"></i>
              </div>
              <div className="d-flex form-group mb-3">
                <input
                  type="text"
                  name="city"
                  className="form-control"
                  id="City"
                  aria-describedby="vornameHelp"
                  placeholder="Stadt"
                  required
                />
                <i className="p-2 bi bi-person-fill icon"></i>
              </div>
              <div className="d-flex form-group mb-3">
                <input
                  type="text"
                  name="country"
                  className="form-control"
                  id="Country"
                  aria-describedby="vornameHelp"
                  placeholder="Land"
                  required
                />
                <i className="p-2 bi bi-person-fill icon"></i>
              </div> */}
              <div className="d-grid form-group mail">
                <label for="salutation-select">E-Mail Adresse</label><span style={{ color: "red" }}>{emailError}</span>
                <div className="d-flex">
                  <input
                    type="email"
                    name="email"
                    className="form-control"
                    id="Email1"
                    aria-describedby="emailHelp"
                    placeholder="E-Mail Adresse"
                    style={{ borderTopRightRadius: "0px", borderBottomRightRadius: "0px" }}
                    required
                  />
                  <i className="p-2 bi bi-envelope-fill icon"></i>
                </div>
              </div>
              <div className="d-grid form-group mt-3 password">
                <label for="salutation-select">Passwort</label>
                <div className="d-flex">
                  <input
                    type="password"
                    name="password"
                    className="form-control"
                    id="Password"
                    placeholder="Passwort"
                    style={{ borderTopRightRadius: "0px", borderBottomRightRadius: "0px" }}
                    required
                  />
                  <i className="p-2 bi bi-key-fill icon"></i>
                </div>
              </div>
              <div className="d-grid form-group mt-3 password">
                <label for="salutation-select">Passwort bestätigen</label><span style={{ color: "red" }}>{passwordError}</span>
                <div className="d-flex">
                  <input
                    type="password"
                    name="passwordConfirm"
                    className="form-control"
                    id="PasswordConfirm"
                    placeholder="Passwort bestätigen"
                    style={{ borderTopRightRadius: "0px", borderBottomRightRadius: "0px" }}
                    required
                  />
                  <i className="p-2 bi bi-key-fill icon"></i>
                </div>
              </div>
              <div className="d-grid gap-2">
                <button className="btn btn-secondary mt-3">Registrieren</button>
              </div>
            </form>
          }
          <div className="d-grid gap-2">
            <button
              className="btn btn-secondary mt-2"
              style={{
                color: "#70AE66",
                backgroundColor: "transparent",
                border: "1px solid #70AE66",
              }}
              onClick={() => navigate("/login")}
            >
              Zum Login
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
