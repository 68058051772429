import { DataGrid } from '@mui/x-data-grid';
import * as React from 'react';
import { formatDate } from '../../util/DateHelper';

function ImagesTable({ images, getSelectedIds, pageChange, page, pageSize, pageTotal }) {

    const columns = [
        { field: 'originalname', headerName: 'Name', width: 130 },
        {
            field: 'image', headerName: 'Bild', width: 110, renderCell: (params) => (
                <img src={params.row.url?.length > 0 ? params.row.url[0] : ""} width="100px" height="100px" style={{ objectFit: "contain" }} alt='' />
            )

        },
        {
            field: 'createdAt', headerName: 'Hochgeladen am', width: 130, valueGetter: (params) => {
                return formatDate(params.row.createdAt)
            }
        }
    ];

    return (
        <>
            <div style={{ width: '100%', background: "rgba(255,255,255,.5)", borderRadius: "15px" }}>
                <DataGrid
                    rows={images}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: { page: page, pageSize: pageSize },
                        },
                    }}
                    pageSizeOptions={[5, 10]}
                    checkboxSelection
                    disableRowSelectionOnClick
                    onRowSelectionModelChange={(data) => {
                        getSelectedIds(data)
                    }}
                    rowCount={pageTotal}
                    onPaginationModelChange={(model) => pageChange(model)}
                    slots={
                        {
                            noRowsOverlay: () => (
                                <div className=""></div>
                            ),
                        }
                    }
                    style={{ border: "none" }}
                />
            </div>
        </>
    );
}

export default ImagesTable;