import * as React from 'react';
import { useEffect, useState } from "react";
import ResultsTable from '../components/tables/ResultsTable';
import useAxiosPrivate from "../hooks/useAxiosPrivate";

function Results() {
    const [selectedIds, setSelectedIds] = useState([]);
    const [results, setResults] = useState([])
    const axiosPrivate = useAxiosPrivate();
    const [isLoading, setIsLoading] = useState(true);
    const [page, setPage] = useState(0)
    const [pageSize, setPageSize] = useState(10)
    const [pageTotal, setPageTotal] = useState(10)
    const [toggle, setToggle] = useState(1);

    const getSelectedIds = (data) => {
        setSelectedIds(data)
    }

    const fetchResults = async () => {
        setIsLoading(true)
        const response = await axiosPrivate.get(`/result/getByUser?offset=${page}&limit=${pageSize}`)
        const data = await response.data.rows

        setResults(data)
        setPageTotal(response.data.count)
        setIsLoading(false)
    }

    useEffect(() => {
        fetchResults().catch(error => console.log(error))
    }, [page, pageSize])

    const deleteResults = async () => {
        await axiosPrivate.delete("/result/delete", { data: { ids: selectedIds } })
        setSelectedIds([])
        fetchResults()
    }

    const archiveResults = async () => {
        await axiosPrivate.put("/result/archive", { ids: selectedIds })
        setSelectedIds([])
        fetchResults()
    }

    const pursueResults = async () => {
        await axiosPrivate.put("/result/pursue", { ids: selectedIds })
        setSelectedIds([])
        fetchResults()
    }

    const undoResults = async () => {
        await axiosPrivate.put("/result/undo", { ids: selectedIds })
        setSelectedIds([])
        fetchResults()
    }

    const pageChange = (model) => {
        setPage(model.page)
        setPageSize(model.pageSize)
    }

    const toggleTab = (tab) => {
        setToggle(tab)
        fetchResults()
    }


    return (
        <div>
            <div>

                <div className='tab'>
                    <ul className='d-flex'>
                        <li className={toggle === 1 ? 'flex-fill active' : 'flex-fill'} onClick={() => toggleTab(1)}>Treffer</li>
                        <li className={toggle === 2 ? 'flex-fill active' : 'flex-fill'} onClick={() => toggleTab(2)}>Archivierte Treffer</li>
                        {/* <li className={toggle === 3 ? 'flex-fill active' : 'flex-fill'} onClick={() => toggleTab(3)}>Verfolgte Treffer</li> */}
                    </ul>
                    {toggle === 1 ?
                        <div div className="row">
                            <div className="col-12 d-flex">
                                {selectedIds.length > 0 ?
                                    <React.Fragment>
                                        <div className="m-2 mt-auto btn btn-primary" onClick={deleteResults}>Ausgewählte Bilder löschen</div>
                                        <div className="m-2 mt-auto btn btn-primary" onClick={archiveResults}>Ausgewählte Bilder archivieren</div>
                                        {/* <div className="m-2 mt-auto btn btn-primary" onClick={pursueResults}>Ausgewählte Bilder verfolgen</div> */}
                                    </React.Fragment>
                                    :
                                    <React.Fragment>
                                        <div className="m-2 mt-auto btn btn-primary inactive">Ausgewählte Bilder löschen</div>
                                        <div className="m-2 mt-auto btn btn-primary inactive">Ausgewählte Bilder archivieren</div>
                                        {/* <div className="m-2 mt-auto btn btn-primary inactive">Ausgewählte Bilder verfolgen</div> */}
                                    </React.Fragment>
                                }
                            </div>
                        </div>
                        :
                        <></>
                    }
                    {toggle === 2 ?
                        <div div className="row">
                            <div className="col-12 d-flex">
                                {selectedIds.length > 0 ?
                                    <React.Fragment>
                                        <div className="m-2 mt-auto btn btn-primary" onClick={undoResults}>Archivieren rückgängig machen</div>
                                    </React.Fragment>
                                    :
                                    <React.Fragment>
                                        <div className="m-2 mt-auto btn btn-primary inactive">Archivieren rückgängig machen</div>
                                    </React.Fragment>
                                }
                            </div>
                        </div>
                        :
                        <></>
                    }
                    {/* {toggle === 3 ?
                        <div div className="row">
                            <div className="col-12 d-flex">
                                {selectedIds.length > 0 ?
                                    <React.Fragment>
                                        <div className="m-2 mt-auto btn btn-primary" onClick={undoResults}>Verfolgung rückgängig machen</div>
                                    </React.Fragment>
                                    :
                                    <React.Fragment>
                                        <div className="m-2 mt-auto btn btn-primary inactive">Verfolgung rückgängig machen</div>
                                    </React.Fragment>
                                }
                            </div>
                        </div>
                        :
                        <></>
                    } */}
                    <div>
                        {isLoading ?
                            <div className='d-flex justify-content-center'>
                                <div className="spinner-border text-primary" role="status">
                                    <span className="sr-only"></span>
                                </div>
                            </div>
                            :
                            (
                                <>
                                    <div className={toggle === 1 ? "show-content" : "tab-content"}>
                                        {toggle === 1 ?
                                            <ResultsTable results={results.filter(item => item.pursue !== true && item.archived !== true)} getSelectedIds={getSelectedIds} pageChange={pageChange} page={page} pageSize={pageSize} pageTotal={pageTotal} filter={"none"} /> : <></>}
                                    </div>
                                    <div className={toggle === 2 ? "show-content" : "tab-content"}>
                                        {toggle === 2 ?
                                            <ResultsTable results={results.filter(item => item.archived === true)} getSelectedIds={getSelectedIds} pageChange={pageChange} page={page} pageSize={pageSize} pageTotal={pageTotal} filter={"archived"} /> : <></>}
                                    </div>
                                    <div className={toggle === 3 ? "show-content" : "tab-content"}>
                                        {toggle === 3 ?
                                            <ResultsTable results={results.filter(item => item.pursue === true)} getSelectedIds={getSelectedIds} pageChange={pageChange} page={page} pageSize={pageSize} pageTotal={pageTotal} filter={"pursued"} /> : <></>}
                                    </div>
                                </>
                            )}
                    </div>

                </div>

            </div>
        </div>
    );
}

export default Results;