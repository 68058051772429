import { Outlet } from "react-router-dom";
import MainNavigation from "./MainNavigation";

const Layout = () => {
  return (
    <>
      <div className="wrapper">
        <MainNavigation />
        <div className="main">
          <nav className="navbar navbar-expand px-3 border-bottom">
            <button className="btn" id="sidebar-toggle" type="button">
              <span className="navbar-toggler-icon"></span>
            </button>
          </nav>
          <main className="content px-3 py-2">
            <Outlet />
          </main>
        </div>
      </div>
    </>
  );
};

export default Layout;
