import { useContext } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import AuthContext from "../context/AuthProvider";
import useAuth from "../hooks/useAuth";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

function MainNavigation() {
    const { setAuth } = useContext(AuthContext);
    const { auth } = useAuth();
    const navigate = useNavigate();
    const axiosPrivate = useAxiosPrivate();

    const logout = async () => {
        await axiosPrivate.get("/auth/logout");
        setAuth({});
        navigate('/login');
    }

    return (
        <aside id="sidebar" className="js-sidebar">
            <div className="p-2">
                <NavLink to="/" className="d-flex text-decoration-none mt-1 align-items-center text-white">
                    <span className="fs-4 d-none d-sm-inline text-black"><NavLink to="/" className="nav-link" end>UNITED CREATIVES</NavLink></span>
                </NavLink>
                <ul className="nav nav-pills flex-column mt-5">
                    <li className="nav-item py-2">
                        <NavLink to="/" className="nav-link" end><img src="/icons/DashboardNavIconBlack.png" width="16px" height="16px" alt="" className="me-2"></img><span>Dashboard</span></NavLink>
                    </li>
                    <li className="nav-item py-2">
                        <NavLink to="/results" className="nav-link"><img src="/icons/TrefferNavIconBlack.png" width="16px" height="16px" alt="" className="me-2"></img><span>Treffer</span></NavLink>
                    </li>
                    <li className="nav-item py-2">
                        <NavLink to="/images" className="nav-link"><img src="/icons/BilderNavIconBlack.png" width="16px" height="16px" alt="" className="me-2"></img><span>Bilder</span></NavLink>
                    </li>
                    {/* <li className="nav-item py-2">
                        <NavLink to="/invoices" className="nav-link"><img src="/icons/BillIconNavBlack.png" width="16px" height="16px" alt="" className="me-2"></img><span>Rechnungen</span></NavLink>
                    </li>                                      */}
                    <li className="nav-item py-2">
                        <NavLink to="/settings" className="nav-link"><img src="/icons/SettingsIconNavBlack.png" width="16px" height="16px" alt="" className="me-2"></img><span>Einstellungen</span></NavLink>
                    </li>
                    {auth?.role === "admin" ?
                        <>
                            <li className="nav-item py-2">
                                <NavLink to="/admin" className="nav-link"><img src="/icons/ProfileIconNavBlack.png" width="16px" height="16px" alt="" className="me-2"></img><span>Admin</span></NavLink>
                            </li>
                        </> :
                        <></>
                    }
                    <li className="nav-item py-2">
                        <NavLink to="/logout" onClick={logout} className="nav-link"><img src="/icons/LogoutIconNavBlack.png" width="16px" height="16px" alt="" className="me-2"></img><span>Ausloggen</span></NavLink>
                    </li>
                </ul>
            </div>
        </aside>

    )
}

export default MainNavigation;