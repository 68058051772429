import * as React from "react";
import { useContext, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from "react-router-dom";
import { axiosPrivate } from "../../api/axios";
import AuthContext from "../../context/AuthProvider";

function ProfileSettings() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { setAuth } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleDelete = async () => {
    setShow(false)
    const response = await axiosPrivate.delete("/user/delete");
    if (response.status === 200) {
      await axiosPrivate.get("/auth/logout");
      setAuth({});
      navigate('/login');
    }
  }

  return (
    <>
      <div className="d-flex flex-column">
        <div className="bg-white shadow-sm p-2 opacity-75 mb-2 bg-body-tertiary rounded-2 ">
          <div className="d-flex flex-column">
            <label className="mt-2">Profil löschen</label>
            <div className="btn btn-primary" onClick={handleShow}><i className="bi bi-trash3"></i></div>
          </div>
          <div className="d-flex flex-column">
            <label className="mt-2">Altes Passwort</label>
            <input className="border rounded-2" type="password" />
            <label className="mt-2">Neues Passwort</label>
            <input className="border rounded-2" type="password" />
            <label className="mt-2">Passwort erneut eingeben</label>
            <input className="border rounded-2" type="password" />
          </div>
          <div className="btn btn-primary d-block mt-2">Submit</div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Achtung</Modal.Title>
        </Modal.Header>
        <Modal.Body>Wollen Sie Ihr Profil wirklich löschen?</Modal.Body>
        <Modal.Footer>
          <div className="btn btn-danger" onClick={handleDelete}>
            Bestätigen
          </div>
          <div className="btn btn-secondary" onClick={handleClose}>
            Abbrechen
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ProfileSettings;
