import * as React from 'react';
import { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import ImagesTable from '../components/tables/ImagesTable';
import ResultsTable from '../components/tables/ResultsTable';
import useAxiosPrivate from "../hooks/useAxiosPrivate";


function Admin() {
    const [selectedIds, setSelectedIds] = useState([]);
    const [images, setImages] = useState([])
    const [results, setResults] = useState([])
    const [selectedUser, setSelectedUser] = useState()
    const axiosPrivate = useAxiosPrivate()
    const [isLoading, setIsLoading] = useState(true)
    const [page, setPage] = useState(0)
    const [pageSize, setPageSize] = useState(10)
    const [pageTotal, setPageTotal] = useState(10)
    const [toggle, setToggle] = useState(1);
    const [addCustomer, setAddCustomer] = useState(false)
    const [emailError, setEmailError] = useState("")

    const fetchImages = async () => {
        setIsLoading(true)
        const response = await axiosPrivate.get(`/admin/getImages?userId=${selectedUser ? selectedUser.id : ""}&offset=${page}&limit=${pageSize}`)
        const data = await response.data.rows

        setImages(data)
        setPageTotal(response.data.count)
        setIsLoading(false)
    }

    const fetchResults = async () => {
        setIsLoading(true)
        const response = await axiosPrivate.get(`/admin/getResults?userId=${selectedUser ? selectedUser.id : ""}&offset=${page}&limit=${pageSize}`)
        const data = await response.data.rows

        setResults(data)
        setPageTotal(response.data.count)
        setIsLoading(false)
    }

    useEffect(() => {
        fetchImages().catch(error => console.log(error))
        fetchResults().catch(error => console.log(error))
    }, [page, pageSize, selectedUser])

    const getSelectedIds = (data) => {
        setSelectedIds(data)
    }

    const deleteImages = async () => {
        await axiosPrivate.delete(`/admin/deleteImage?userId=${selectedUser ? selectedUser.id : ""}`, { data: { ids: selectedIds } })
        setSelectedIds([])
        fetchImages()
    }

    const pageChange = (model) => {
        setPage(model.page)
        setPageSize(model.pageSize)
    }

    const findUser = async (e) => {
        e.preventDefault()
        const formData = new FormData(e.target);
        const input = Object.fromEntries(formData)
        fetchUserByEmail(input.email)
    }

    const fetchUserByEmail = async (email) => {
        const response = await axiosPrivate.get(`admin/getUser?email=${email}`)
        const data = await response.data
        setSelectedUser(data)
    }

    const changeRole = async (role) => {
        await axiosPrivate.put("admin/editUser", {
            role: role,
            active: selectedUser.active,
            id: selectedUser ? selectedUser.id : ""
        })
        fetchUserByEmail(selectedUser.email)
    }

    const toggleActive = async (e) => {
        await axiosPrivate.put("admin/editUser", {
            role: selectedUser.role,
            active: e.target.checked,
            id: selectedUser ? selectedUser.id : ""
        })
        fetchUserByEmail(selectedUser.email)
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const formData = new FormData(e.target);

            const user = Object.fromEntries(formData);
            await axiosPrivate.post("admin/createUser", user);
            setAddCustomer(false)
        } catch (err) {
            if (err?.response?.status === 422) {
                setEmailError("Email already in use")
            }
        }
    };

    return (
        <div>
            <div className="row">
                <div className='d-flex justify-content-evenly ms-2 p-3 my-3 shadow-sm opacity-75 bg-body-tertiary border bg-white rounded-2'>
                    <div className='mx-2'>
                        <label>Neuer Kunde</label>
                        <br />
                        <button
                            className="btn btn-primary"
                            style={{ background: "#79E945" }}
                            onClick={() => setAddCustomer(true)}
                        >
                            <i className="bi bi-plus fs-6"></i>
                        </button>
                    </div>
                    <form onSubmit={findUser}>
                        <label className='mx-2'>User Email</label>
                        <br />
                        <input className="border rounded-2 mx-2 p-2" type="text" name="email" />
                        <button
                            className="btn btn-primary"
                            style={{ background: "#79E945" }}
                        >
                            <i className="bi bi-search fs-6"></i>
                        </button>
                    </form>
                    {selectedUser ?
                        <>
                            <div className='mx-2 mt-2'>
                                <label>Name</label>
                                <p>{selectedUser.firstName + " " + selectedUser.lastName}</p>
                            </div>
                            <div className='mx-2 mt-2'>
                                <label>Suchintervall</label>
                                <p>{selectedUser.nextSearchTimespan === 7 ? "Wöchentlich" : "Monatlich"}</p>
                            </div>
                            <div className='mx-2 mt-2'>
                                <label>Rolle</label>
                                <Dropdown >
                                    <Dropdown.Toggle variant="primary" id="dropdown-basic" >
                                        {selectedUser.role}
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item value="user" onClick={() => changeRole("user")}>user</Dropdown.Item>
                                        <Dropdown.Item value="admin" onClick={() => changeRole("admin")}>admin</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                            <div className='mx-2 mt-2'>
                                <label>Aktiv</label>
                                <div className="form-check form-switch" onClick={toggleActive}>
                                    {selectedUser.active === true ?
                                        <input className="form-check-input fs-5" type="checkbox" role="switch" id="flexSwitchCheckDefault" checked /> :
                                        <input className="form-check-input fs-5" type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                                    }
                                </div>
                            </div>
                        </> :
                        <div className='mx-2 mt-2'>
                            <p>Kein user mit der angegebenen Email Adresse gefunden.</p>
                        </div>
                    }
                </div>

                {addCustomer ?
                    <React.Fragment>
                        <form onSubmit={handleSubmit}>
                            <div className='row d-flex justify-content-between ms-2 p-3 my-3 shadow-sm opacity-75 bg-body-tertiary border bg-white rounded-2'>
                                <div className='col-md-6'>
                                    <span style={{ color: "red" }}>{emailError}</span>
                                    <div className="d-flex form-group mail mb-3">
                                        <input
                                            type="email"
                                            name="email"
                                            className="form-control"
                                            id="Email1"
                                            aria-describedby="emailHelp"
                                            placeholder="E-Mail Adresse"
                                        />
                                        <i className="p-3 bi bi-envelope-fill icon"></i>
                                    </div>
                                    <div className="d-grid form-group mb-3">

                                        {/* <label for="salutation-select">Anrede</label> */}
                                        <div className="d-flex">
                                            <select name="salutation" id="salutation-select" style={{ width: "100%", borderRadius: "5px", border: "none", borderTopRightRadius: "0px", borderBottomRightRadius: "0px", paddingLeft: "9px" }}>
                                                <option value="Herr">Herr</option>
                                                <option value="Frau">Frau</option>
                                            </select>
                                            <i className="p-3 bi bi-person-fill icon"></i>
                                        </div>
                                    </div>
                                    <div className="d-grid form-group mb-3">
                                        {/* <label for="salutation-select">Titel</label> */}
                                        <div className="d-flex">
                                            <input
                                                type="text"
                                                name="title"
                                                className="form-control"
                                                id="Title"
                                                aria-describedby="titelHelp"
                                                placeholder="Titel"
                                                style={{ borderTopRightRadius: "0px", borderBottomRightRadius: "0px" }}
                                            />
                                            {/* <select name="title" id="title-select" style={{ width: "100%", borderRadius: "5px", border: "none" }}>
                  <option value="Doktor">Doktor</option>
                </select>*/}
                                            <i className="p-3 bi bi-person-fill icon"></i>
                                        </div>
                                    </div>
                                    <div className="d-flex form-group mb-3">
                                        <input
                                            type="text"
                                            name="firstName"
                                            className="form-control"
                                            id="FirstName"
                                            aria-describedby="vornameHelp"
                                            placeholder="Vorname"
                                        />
                                        <i className="p-3 bi bi-person-fill icon"></i>
                                    </div>
                                    <div className="d-flex form-group mb-3">
                                        <input
                                            type="text"
                                            name="lastName"
                                            className="form-control"
                                            id="LastName"
                                            placeholder="Nachname"
                                        />
                                        <i className="p-3 bi bi-person-fill icon"></i>
                                    </div>
                                    <div className="d-flex form-group mb-3">
                                        <input
                                            type="text"
                                            name="street"
                                            className="form-control"
                                            id="Street"
                                            aria-describedby="vornameHelp"
                                            placeholder="Straße"
                                        />
                                        <i className="p-3 bi bi-person-fill icon"></i>
                                    </div>
                                    <div className="d-flex form-group mb-3">
                                        <input
                                            type="text"
                                            name="houseNumber"
                                            className="form-control"
                                            id="HouseNumber"
                                            aria-describedby="hausnummerHelp"
                                            placeholder="Hausnummer"
                                            required
                                        />
                                        <i className="p-3 bi bi-person-fill icon"></i>
                                    </div>
                                    <div className="d-flex form-group mb-3">
                                        <input
                                            type="text"
                                            name="postalCode"
                                            className="form-control"
                                            id="PostalCode"
                                            aria-describedby="vornameHelp"
                                            placeholder="PLZ"
                                        />
                                        <i className="p-3 bi bi-person-fill icon"></i>
                                    </div>
                                    <div className="d-flex form-group mb-3">
                                        <input
                                            type="text"
                                            name="city"
                                            className="form-control"
                                            id="City"
                                            aria-describedby="vornameHelp"
                                            placeholder="Stadt"
                                        />
                                        <i className="p-3 bi bi-person-fill icon"></i>
                                    </div>
                                    <div className="d-flex form-group mb-3">
                                        <input
                                            type="text"
                                            name="country"
                                            className="form-control"
                                            id="Country"
                                            aria-describedby="vornameHelp"
                                            placeholder="Land"
                                        />
                                        <i className="p-3 bi bi-person-fill icon"></i>
                                    </div>
                                </div>
                                {/* <div className='col-md-6'>
                                    <div className="d-flex form-group mb-3">
                                        <input
                                            type="text"
                                            name="firstName"
                                            className="form-control"
                                            id="FirstName"
                                            aria-describedby="vornameHelp"
                                            placeholder="IBAN"
                                        />
                                        <i className="p-3 bi bi-person-fill icon"></i>
                                    </div>
                                    <div className="d-flex form-group mb-3">
                                        <input
                                            type="text"
                                            name="lastName"
                                            className="form-control"
                                            id="LastName"
                                            placeholder="BIC"
                                        />
                                        <i className="p-3 bi bi-person-fill icon"></i>
                                    </div>
                                    <div className="d-flex form-group mb-3">
                                        <input
                                            type="text"
                                            name="street"
                                            className="form-control"
                                            id="Street"
                                            aria-describedby="vornameHelp"
                                            placeholder="Kontoinhaber"
                                        />
                                        <i className="p-3 bi bi-person-fill icon"></i>
                                    </div>
                                    <div className="d-flex form-group mb-3">
                                        <input
                                            type="text"
                                            name="postalCode"
                                            className="form-control"
                                            id="PostalCode"
                                            aria-describedby="vornameHelp"
                                            placeholder="Bank"
                                        />
                                        <i className="p-3 bi bi-person-fill icon"></i>
                                    </div>

                                </div> */}
                                <button type="button" className="btn btn-secondary mt-3" onClick={() => setAddCustomer(false)}>Abbrechen</button>
                                <button type="submit" className="btn btn-secondary mt-3">Kunde anlegen</button>

                            </div>
                        </form>
                    </React.Fragment>
                    : <></>}
                <div className="col-12 col-xl-6 d-flex text-center">
                    {selectedIds.length > 0 && toggle === 1 ?
                        <div className="m-2 mt-auto btn btn-primary" onClick={deleteImages}>Ausgewählte Bilder löschen</div>
                        :
                        <div className="m-2 mt-auto btn btn-primary inactive">Ausgewählte Bilder löschen</div>
                    }
                </div>
                <div className="col-12 col-md-6 d-flex justify-content-between">

                </div>
            </div>
            {isLoading ?
                <div className="spinner-border text-primary" role="status">
                    <span className="sr-only"></span>
                </div>
                :
                <div>
                    <div className='tab'>
                        <ul className='d-flex'>
                            <li className={toggle === 1 ? 'flex-fill active' : 'flex-fill'} onClick={() => setToggle(1)}>Bilder</li>
                            <li className={toggle === 2 ? 'flex-fill active' : 'flex-fill'} onClick={() => setToggle(2)}>Treffer</li>
                        </ul>
                        <>
                            <div className={toggle === 1 ? "show-content" : "tab-content"}>
                                <ImagesTable images={images} getSelectedIds={getSelectedIds} pageChange={pageChange} page={page} pageSize={pageSize} pageTotal={pageTotal} />
                            </div>
                            <div className={toggle === 2 ? "show-content" : "tab-content"}>
                                <ResultsTable results={results} getSelectedIds={getSelectedIds} pageChange={pageChange} page={page} pageSize={pageSize} pageTotal={pageTotal} filter={"admin"} />
                            </div>
                        </>
                    </div>
                </div>
            }
        </div>
    );
}

export default Admin;