import { DataGrid } from '@mui/x-data-grid';
import * as React from 'react';
import { formatDate } from '../../util/DateHelper';

function BlacklistTable({ blacklistItems, getSelectedIds, pageChange, page, pageSize, pageTotal }) {

    const columns = [
        { field: 'url', headerName: 'Url', width: 250 },
        {
            field: 'createdAt', headerName: 'Hochgeladen am', width: 130, valueGetter: (params) => {
                return formatDate(params.row.createdAt)
            }
        }
    ];

    return (
        <>
            <div style={{ width: '100%', background: "rgba(255,255,255,.5)", borderRadius: "15px" }}>
                <DataGrid
                    rows={blacklistItems}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: { page: page, pageSize: pageSize },
                        },
                    }}
                    pageSizeOptions={[5, 10]}
                    checkboxSelection
                    disableRowSelectionOnClick
                    rowCount={pageTotal}
                    onRowSelectionModelChange={(data) => {
                        getSelectedIds(data)
                    }}
                    onPaginationModelChange={(model) => pageChange(model)}
                    slots={
                        {
                            noRowsOverlay: () => (
                                <div className=""></div>
                            ),
                        }
                    }
                    style={{ border: "none" }}
                />
            </div>
        </>
    );
}

export default BlacklistTable;