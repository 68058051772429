import * as React from 'react';
import { useEffect, useState } from "react";
import { useDropzone } from 'react-dropzone';
import ImagesTable from '../components/tables/ImagesTable';
import useAxiosPrivate from "../hooks/useAxiosPrivate";


function Images() {
    const [selectedIds, setSelectedIds] = useState([]);
    const [images, setImages] = useState([])
    const axiosPrivate = useAxiosPrivate()
    const [isLoading, setIsLoading] = useState(true)
    const { acceptedFiles, getRootProps, getInputProps, isDragActive } = useDropzone()
    const [page, setPage] = useState(0)
    const [pageSize, setPageSize] = useState(10)
    const [pageTotal, setPageTotal] = useState(10)

    const fetchImages = async () => {
        setIsLoading(true)
        const response = await axiosPrivate.get(`/image/get?offset=${page}&limit=${pageSize}`)
        const data = await response.data.rows

        setImages(data)
        setPageTotal(response.data.count)
        setIsLoading(false)
    }


    useEffect(() => {
        fetchImages().catch(error => console.log(error))
    }, [page, pageSize])

    const getSelectedIds = (data) => {
        setSelectedIds(data)
    }

    const deleteImages = async () => {
        await axiosPrivate.delete("/image/delete", { data: { ids: selectedIds } })
        setSelectedIds([])
        fetchImages()
    }

    const uploadImages = async (event) => {
        event.preventDefault()

        if (acceptedFiles.length + pageTotal > 500) {
            alert("Upload exceeds limit")
            return
        }
        setIsLoading(true)
        const formData = new FormData();

        acceptedFiles.forEach(file => {
            formData.append('image', file);
        })

        await axiosPrivate.post("image/createBucket", formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        fetchImages()
        acceptedFiles.splice(0, acceptedFiles.length);
    }

    const pageChange = (model) => {
        setPage(model.page)
        setPageSize(model.pageSize)
    }

    return (
        <div>
            <div className="row">
                <div className="col-12 col-xl-6 d-flex text-center">
                    <div className="dropzone rounded-2 m-2 p-4">
                        <form onSubmit={uploadImages}>
                            <div>
                                Current Images count: {pageTotal} / 500
                            </div>
                            {/* <input name="image" type="file" accept="image/png, image/jpeg" onChange={e => setFile(e.target.files[0])} /> */}
                            <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                {
                                    isDragActive ?
                                        <p>Drag 'n' drop some files here, or click to select files</p> :
                                        <p>Drag 'n' drop some files here, or click to select files</p>
                                }
                                {acceptedFiles ? acceptedFiles.map((number) =>
                                    <p key={number.name}>{number.name}</p>
                                ) : null}
                            </div>
                            <br />
                            <button className='mt-2 btn btn-primary' disabled={acceptedFiles.length === 0}>Hochladen</button>
                        </form>

                    </div>
                    {selectedIds.length > 0 ?
                        <div className="m-2 mt-auto btn btn-primary" onClick={deleteImages}>Ausgewählte Bilder löschen</div>
                        :
                        <div className="m-2 mt-auto btn btn-primary inactive">Ausgewählte Bilder löschen</div>
                    }
                </div>
                <div className="col-12 col-md-6 d-flex justify-content-between">

                </div>
            </div>
            {isLoading ?
                <div className="spinner-border text-primary" role="status">
                    <span className="sr-only"></span>
                </div>
                :
                <ImagesTable images={images} getSelectedIds={getSelectedIds} pageChange={pageChange} page={page} pageSize={pageSize} pageTotal={pageTotal} />
            }
        </div>
    );
}

export default Images;