import { jwtDecode } from "jwt-decode";
import { useRef, useState } from 'react';
import CookieConsent from "react-cookie-consent";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import axios from "../../api/axios";
import useAuth from "../../hooks/useAuth";
const LOGIN_URL = "/auth/login";

const Login = () => {
  const { setAuth } = useAuth();

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  const errRef = useRef();
  const [errMsg, setErrMsg] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const formData = new FormData(e.target);
      const user = Object.fromEntries(formData);
      const response = await axios.post(LOGIN_URL, user, {
        withCredentials: true,
      });

      const accessToken = response?.data?.token;
      const token = jwtDecode(accessToken)
      const role = token.role
      setAuth({ user, accessToken, role });
      navigate(from, { replace: true });
    } catch (err) {
      if (!err?.response) {
        setErrMsg('No Server Response');
      } else if (err.response?.status === 400) {
        setErrMsg('Missing Username or Password');
      } else if (err.response?.status === 401) {
        setErrMsg('Unauthorized');
      } else {
        setErrMsg('Login Failed');
      }
      console.log("error")
      errRef.current.focus();
    }
  };

  return (
    <>
      <CookieConsent
        location="bottom"
        buttonText="I understand"
        cookieName="cookieconsent"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
      >
        This website uses technical cookies to enhance the user experience.{" "}
      </CookieConsent>
      <div className="position-absolute top-50 start-50 translate-middle">
        <div className="d-flex justify-content-center fw-bold fs-3">
          <p>Willkommen bei United Creatives!</p>
        </div>
        <div className="container mt-5">
          <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
          <form onSubmit={handleSubmit}>
            <div className="d-flex form-group mail">
              <input
                type="email"
                name="email"
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder="E-Mail Adresse"
              />
              <i className="p-3 bi bi-envelope-fill icon"></i>
            </div>
            <div className="d-flex form-group mt-3 password">
              <input
                type="password"
                name="password"
                className="form-control"
                id="exampleInputPassword1"
                placeholder="Passwort"
              />
              <i className="p-3 bi bi-key-fill icon"></i>
            </div>
            <p className="ps-3 mt-2">
              <NavLink to="/forgotPassword">Passwort vergessen?</NavLink>
            </p>
            <div className="d-grid gap-2">
              <button className="btn btn-secondary mt-3">Einloggen</button>
            </div>
          </form>
          <div className="d-grid gap-2">
            <button
              className="btn btn-secondary mt-2"
              style={{
                color: "#70AE66",
                backgroundColor: "transparent",
                border: "1px solid #70AE66",
              }}
              onClick={() => navigate("/register")}
            >
              Registrieren
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
